<template>
  <div>
    <b-button
      variant="warning"
      :size="size"
      @click="clickModal"
      :disabled="disabled"
      >Reserve
      <i class="far fa-check-circle"></i>
      <!-- <b-icon icon="folder-check" aria-hidden="true"></b-icon> -->
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Purchase Request"
      id="invoice_detail"
      ref="modal"
      size="lg"
    >
      <b-overlay :show="loading">
        <b-row style="padding-bottom: 80px">
          <!-- <b-col md="12" class="my-1">
            <p>
              Please choose a purchase source and set the purchase amount.
            </p></b-col
          > -->
          <!-- <b-form-group> -->
          <b-col md="12" class="my-1" v-if="0">
            <b-form-group
              class="status"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Purchase Source"
            >
              <b-form-radio-group
                v-model="source_type"
                :options="options.source_type"
                text-field="label"
                aria-label="Individual flavours"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              class="supplier required"
              label-for="supplier"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Supplier Name"
            >
              <template v-if="source_type == 1">
                <SelectLoading
                  v-if="options.supplier == 0"
                  :size="0.7"
                ></SelectLoading>
                <sv-select
                  v-model="supplier"
                  :placeholder="$t('page.please_choose')"
                  :options="options.supplier"
                  :reduce="(options) => options.value"
                  :selectable="(options) => !options.disabled"
                  @input="changeSupplier($event)"
                  label="label"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                    v-if="option.index"
                  >
                    <div class="d-center" :class="'level_' + option.index">
                      <span v-if="option.index == 2">--</span>
                      {{ option.label }}
                    </div>
                  </template>
                </sv-select> </template
              ><template v-else-if="source_type == 2">
                <b-form-input
                  v-model="supplier_text"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </template>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              label-for="channel"
              class="channel required"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Channel"
            >
              <SelectLoading
                v-if="
                  (!options.channel || options.channel.length == 0) &&
                  supplier != '' &&
                  supplier != null
                "
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="channel"
                :placeholder="$t('page.please_choose')"
                :options="options.channel"
                :reduce="(options) => options.value"
                label="label"
              >
                <!-- :clearable="false" -->
              </sv-select>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1" v-if="purchaseTargetIdArr == null">
            <b-form-group
              class="qty required"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Quantity"
            >
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text> / {{ maxQty }} </b-input-group-text>
                </template>
                <b-form-input
                  v-model="qty"
                  type="number"
                  :min="1"
                  :max="maxQty"
                  :disabled="qtyDisabled"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              class="required"
              label-for="delivery_address"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Delivery to"
            >
              <SelectLoading
                v-if="
                  !baseConfig.purchase_delivery_to ||
                  baseConfig.purchase_delivery_to.length == 0
                "
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="delivery_address"
                :placeholder="$t('page.please_choose')"
                :options="baseConfig.purchase_delivery_to"
                :reduce="(options) => options.value"
                label="label"
              >
                <!-- :clearable="false" -->
              </sv-select>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              label-for="purchase_date"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Purchase Date"
            >
              <b-input-group>
                <template v-slot:append>
                  <div
                    v-if="purchase_date"
                    class="datepicker-x"
                    @click="initialTime()"
                  >
                    <b-icon-x font-scale="1.45"></b-icon-x>
                  </div>
                  <b-input-group-text>
                    <b-icon-calendar2-date></b-icon-calendar2-date>
                  </b-input-group-text>
                </template>
                <b-form-datepicker
                  v-model="purchase_date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  :hide-header="true"
                  locale="de"
                ></b-form-datepicker>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              class="reserve_no"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Reserve No."
            >
              <b-input-group>
                <b-form-input
                  v-model="reserve_no"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              class="settlement_type required"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Settlement Type"
            >
              <b-form-radio-group
                id="settlement_type"
                v-model="settlement_type"
                :options="options.settlement_type"
                text-field="label"
                name="settlement_type"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1" v-if="settlement_type == 2">
            <b-form-group
              class="payment_by required"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Payment by"
            >
              <SelectLoading
                v-if="!options.payment_by || options.payment_by.length == 0"
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="payment_by"
                :placeholder="$t('page.please_choose')"
                :options="options.payment_by"
                :reduce="(options) => options.value"
                label="label"
              >
                <!-- :clearable="false" -->
              </sv-select>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1" v-if="settlement_type == 2">
            <!-- :class="{
                form_error: isNaN(purchase_price.replace(/,/g, '.'))
              }" -->
            <b-form-group
              class="purchase_price required"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Purchase Price"
            >
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text> € </b-input-group-text>
                </template>
                <b-form-input
                  v-model="purchase_price"
                  :disabled="qtyDisabled"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1" v-if="settlement_type == 2">
            <b-form-group
              class="total_payment_amount"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Total Payment Amount"
            >
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text> € </b-input-group-text>
                </template>
                <b-form-input
                  v-model="total_payment_amount"
                  disabled
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            class="my-1"
            v-if="settlement_type == 2 && payment_by == 'Private Person'"
          >
            <b-form-group
              class="payment_person required"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Payment Person"
            >
              <SelectLoading
                v-if="
                  !options.payment_person || options.payment_person.length == 0
                "
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="payment_person"
                :placeholder="$t('page.please_choose')"
                :options="options.payment_person"
                :reduce="(options) => options.value"
                label="label"
              >
                <!-- :clearable="false" -->
              </sv-select>
            </b-form-group>
          </b-col>

          <!-- </b-form-group> -->
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
import SelectLoading from '@/components/Loading/Index';
import { mapState } from 'vuex';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
export default {
  name: 'CreatePurchase',
  data: function () {
    return {
      showModal: false,
      loading: false,
      source_type: 1, // Must be an array reference!
      supplier: null,
      channel: null,
      supplier_text: null,
      qty: 0,
      purchase_price: '0',
      purchase_date: null,
      reserve_no: null,
      delivery_address: null,
      submitDisabled: false,
      settlement_type: null,
      payment_by: null,
      payment_person: null,
      total_payment_amount: 0,
      options: {
        settlement_type: [
          { label: 'Partner Contract', value: 1 },
          { label: 'Direct Payment', value: 2 }
        ],
        payment_by: [],
        payment_person: [],
        source_type: [
          { label: 'Others', value: 2 },
          { label: 'Merchant Partner', value: 1 }
        ],
        supplier: [],
        delivery_address: [],
        channel: []
      }
    };
  },
  components: { SelectLoading },
  props: [
    'purchaseTargetId',
    'purchaseTargetIdArr',
    'maxQty',
    'disabled',
    'size',
    'initFn',
    'buttonSize',
    'qtyDisabled',
    'orderNumber',
    'brand'
  ],
  methods: {
    clickModal() {
      // if (this.orderNumber == '') {
      //   this.$store.dispatch(PROMPT_STATUS, {
      //     dismissCountDown: 5, //初始提示作用于定时器
      //     alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
      //     alertText: 'Brand Article Number is required.' // 提示内容
      //   });
      //   return false;
      // }
      if (
        this.options.delivery_address == undefined ||
        this.options.delivery_address.length == 0
      ) {
        if (Object.keys(this.baseConfig).length == 0) {
          apiBus.purchase
            .getBaseConfig()
            .then((data) => {
              console.log('getBaseConfig数据缓存vuex1');
              this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
              this.options.delivery_address = this.baseConfig.delivery_address;
              // this.delivery_address = this.baseConfig.purchase_delivery_to[0].value;
              // 给Order Type赋值
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      apiBus.purchase
        .getSupplierList({
          purchase_target_id: this.purchaseTargetId,
          status: 10
        })
        .then((data) => {
          let options = [];
          Object.keys(data.data.data.supplier_list).forEach((item) => {
            options.push({
              label: item,
              value: item.replace(/ /g, '_'),
              index: 1,
              disabled: true
            });
            // console.log(item);
            data.data.data.supplier_list[item].forEach((x) => {
              options.push({
                label: x.label,
                value: x.value,
                index: 2,
                disabled:
                  x.value == 523 && this.brand == 'BALENCIAGA' ? true : false
              });
            });
          });
          this.options.supplier = options;
          console.log(this.options.supplier);
          if (options[1].index == 2)
            this.supplier =
              this.options.supplier[
                options.findIndex((item) => item.index == 2)
              ].value;
          // 给Order Type赋值
        })
        .catch((error) => {
          console.log(error);
        });

      apiBus.purchase
        .getSettlementList()
        .then((data) => {
          this.options.payment_by = data.data.data.payment_by;
          this.options.payment_person = data.data.data.payment_person;
        })
        .catch((error) => {
          console.log(error);
        });

      this.source_type = 1;
      this.supplier_text = null;
      this.qty = this.maxQty;
      this.payment_person = null;
      this.settlement_type = null;
      this.payment_by = null;
      this.payment_person = null;
      this.purchase_price = '0';

      if (this.baseConfig.purchase_delivery_to)
        // this.delivery_address = this.baseConfig.purchase_delivery_to[0].value;
        this.purchase_date = null;
      this.showModal = true;
    },

    changeSupplier(e) {
      this.options.channel = [];
      this.channel = null;
      apiBus.purchase
        .getChannelList({ supplier: e, status: 10 })
        .then((data) => {
          console.log(data);
          this.options.channel = data.data.data.channel_list;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submit() {
      console.log('submit');
      // 判断 settlement_type
      if (
        this.settlement_type == null ||
        (this.settlement_type == 2 &&
          (this.payment_by == null ||
            this.payment_by == '' ||
            this.purchase_price == null ||
            this.purchase_price == '' ||
            (this.payment_by == 'Private Person' &&
              (this.payment_person == null || this.payment_person == '')))) ||
        this.channel == null ||
        this.channel == '' ||
        this.delivery_address == null ||
        this.delivery_address == ''
      ) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        console.log('判断 settlement_type 不通过');
        return false;
      }

      if (isNaN(this.purchase_price.replace(/,/g, '.'))) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Please fill in the price correctly!' // 提示内容
        });
        return false;
      }

      if (this.source_type == 1) {
        if (this.supplier == null || this.supplier == '') {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Fields marked with * are required!' // 提示内容
          });
          return false;
        }
      } else if (this.source_type == 2) {
        console.log(this.supplier_text);
        if (
          this.supplier_text == null ||
          this.supplier_text == '' ||
          this.channel == null ||
          this.channel == ''
        ) {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Fields marked with * are required!' // 提示内容
          });
          return false;
        }
      }
      if (
        this.purchaseTargetIdArr == null &&
        (this.qty == null || this.qty == '')
      ) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
      } else {
        console.log(!Number.isInteger(this.purchase_price));
        if (
          this.purchaseTargetIdArr == null &&
          (isNaN(Number(this.qty)) ||
            this.qty == 'e' ||
            this.qty > this.maxQty ||
            this.qty <= 0)
        ) {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Please fill in Quantity correctly!' // 提示内容
          });
        } else {
          this.submitDisabled = true;
          this.loading = true;
          this.payment_by != 'Private Person'
            ? (this.payment_person = null)
            : '';
          let submitData;
          if (this.purchaseTargetId) {
            submitData = {
              purchase_target_id: [this.purchaseTargetId],
              supplier: this.supplier,
              channel: this.channel,
              qty: this.qty,
              // purchase_price: this.purchase_price,
              delivery_address: this.delivery_address,
              purchase_date: this.purchase_date,
              reserve_no: this.reserve_no,
              settlement_type: this.settlement_type,
              payment_by: this.payment_by,
              purchase_price: this.purchase_price,
              payment_person: this.payment_person
            };
          } else {
            submitData = {
              purchase_target_id: this.purchaseTargetIdArr,
              supplier: this.supplier,
              channel: this.channel,
              delivery_address: this.delivery_address,
              purchase_date: this.purchase_date,
              reserve_no: this.reserve_no,
              settlement_type: this.settlement_type,
              payment_by: this.payment_by,
              purchase_price: this.purchase_price,
              payment_person: this.payment_person
            };
          }
          apiBus.purchase
            .purchaseProduct(submitData)
            .then((data) => {
              console.log(data);
              this.submitDisabled = false;
              this.loading = false;
              this.showModal = false;
              this.$store.dispatch(PROMPT_STATUS, {
                dismissCountDown: 5, //初始提示作用于定时器
                alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
                alertText: 'Successfully!' // 提示内容
              });
              if (document.getElementById('purchase-overview-list')) {
                var scroll_top = 0;
                if (
                  document.documentElement &&
                  document.documentElement.scrollTop
                ) {
                  scroll_top = document.documentElement.scrollTop;
                } else if (document.body) {
                  scroll_top = document.body.scrollTop;
                }
                var divTop = document
                  .getElementById('purchase-overview-list')
                  .getBoundingClientRect().top;
                console.log(divTop);
                window.scrollTo(
                  0,
                  divTop +
                    scroll_top -
                    document.documentElement.clientHeight / 3
                );
              }
              this.showModal = false;
              this.initFn(1);
            })
            .catch((error) => {
              this.submitDisabled = false;
              this.loading = false;
              this.$store.dispatch(PROMPT_STATUS, {
                dismissCountDown: 5, //初始提示作用于定时器
                alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
                alertText:
                  error.data == undefined ? error.message : error.data.message // 提示内容
              });
            });
        }
      }
    },
    initialTime() {
      this.purchase_date = null;
    },
    throttling(n, type) {
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        this.conversion(n, type);
      }, 1000);
    },

    forDight(Dight, How) {
      Dight = Math.round(Dight * Math.pow(10, How)) / Math.pow(10, How);
      return Dight;
    },

    conversion(n, type) {
      if (n != null)
        switch (type) {
          case 'purchase_price':
            if (this.qty != null)
              // console.log(n * this.purchase_price.replace(/,/g, '.'));
              this.total_payment_amount = this.forDight(
                n.replace(/,/g, '.') * this.qty,
                '2'
              )
                .toString()
                .replace('.', ',');
            break;

          case 'qty':
            if (this.purchase_price != null)
              this.total_payment_amount = this.forDight(
                n * this.purchase_price.replace(/,/g, '.'),
                '2'
              )
                .toString()
                .replace('.', ',');
            break;
        }
    },
    init() {}
  },
  computed: {
    ...mapState({
      baseConfig: (state) => state.baseconfig.baseconfig
    })
  },
  mounted() {
    this.init();
  },
  watch: {
    qty(n) {
      this.throttling(n, 'qty');
    },
    purchase_price(n) {
      this.throttling(n, 'purchase_price');
    }
  }
};
</script>
<style scoped></style>
